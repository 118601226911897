import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import SEO from "../components/seo"
import Container from "../components/container"
import VerticalAlign from "../components/verticalAlign"
import Header from "../components/header"
import Page from "../components/page"
import styled from "@emotion/styled"
import ReactMarkdown from "react-markdown"
import { graphql, Link } from "gatsby"
import { FaTwitter, FaInstagram, FaFacebookF } from "react-icons/fa"
import axios from "axios"
const isBrowser = typeof window !== "undefined"

const Styles = styled.div`
min-height: 100vh;
@media screen and (max-width: 400px) {
  min-height: 50vh;
}

.my-background {
  min-height: 100vh;
  padding-top: 25vh;

  @media screen and (max-width: 400px) {
    padding-top: 0px;
    min-height: 50vh;
  }
}

.field {
  margin-bottom: 12px;
}

.my-box {
  max-width: 333px;
  margin-bottom: 100px;

  @media screen and (max-width: 400px) {
    width: 100%;
    max-width: 100%;
    
  }

  display: block;
  margin: 0px auto;
  color: white;
  text-align: center;
  font-size: 16px;
}



.my-card {
  width: 100%;
  height: 100%;
  padding: 30px;
  padding-top: 42px;
  background: linear-gradient(180deg, #121212 0%, #000000 100%);
  border-radius: 4px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  
  @media screen and (max-width: 400px) {
    min-height: 100vh;
    padding-top: 26vh;
  }
}

.title {
  color: white;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 27px;
}

.subtitle {
  color: white;
  font-size: 16px;
  line-height: 19px;
}

.my-logo {
  width: 60px;
  display: block;
  margin: 0px auto;
  margin-bottom: 30px;

  @media screen and (max-width: 400px) {
    display: none;
  }
}

.button {
  font-size: 16px !important;
}


.email {
  text-align: center;

  @media screen and (min-width: 800px) {
    text-align: right;
  }

  a {
    color: white;
  }
}

.my-already {
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  
  span {
    color: #e1c270;
    cursor: pointer;
  }
}

.button.is-warning {
  background: #e1c270;
  font-size: 1.25rem;
  width: 100%;
}

.my-number-grid {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: minmax(12px, 1fr) minmax(12px, 1fr) minmax(12px, 1fr) 10px minmax(12px, 1fr) minmax(12px, 1fr) minmax(12px, 1fr);

  input {
    padding: 0px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}
`

export default class extends React.Component {
  constructor() {
    super()
    this.state = {}
  }
  componentDidMount() {}

  render() {
    const background = this.props.data.background.childImageSharp.fluid
    const card = this.props.data.card.childImageSharp.fluid
    const logo = this.props.data.logo.childImageSharp.fluid

    return (
      <Layout>
        <SEO title="Sign Up" />
        
        <Header />

        <Styles>
          <BackgroundImage className="my-background" fluid={background}>
            <div className="my-box">
              <div
                
                className="my-card"
                style={{ height: "100%", width: "100%" }}
              >

                <div>
                  <Img fluid={logo} className="my-logo" />
                </div>

                <div className="title">Welcome to Last Card</div>

                <div className="subtitle">Sign up for a free account.</div>

                <div className="field">
                  <input
                    className="input"
                    type="email"
                    placeholder="Email Address"
                    required
                    style={{ textAlign: "center", fontSize: 16, height: 50,  }}
                  />
                </div>

                <div className="field">
                  <div className="button is-warning is-large" style={{height: 50}}>
                    <b>Sign Up</b>
                  </div>
                </div>

                <div className="my-already" >
                  <small>
                    Already have an account? <Link to="log-in"><span>Log in</span></Link>.
                  </small>
                </div>
              </div>
            </div>
          </BackgroundImage>

        </Styles>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    card: file(relativePath: { eq: "card.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
